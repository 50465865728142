import ZeroAndSlicks from "../images/steve/zero-and-the-slicks.jpg";
import GenericBand1 from "../images/steve/the-generic-band.jpg";
import GenericBand2 from "../images/steve/the-generic-band-1986.jpg";
import GenericBand3 from "../images/steve/red-shirt-band.jpg";
import LeiAloha from "../images/steve/the-lei-aloha-serenaders.jpg";
import TurksSteve from "../images/steve/steve-turks.jpg";
import GrandSteve from "../images/steve/steve-grand-canyon-guitar.jpg";
import LapSteve from "../images/steve/lap-steve.jpg";
import Steve1986 from "../images/steve/steve-1986.jpg";
import SteveGreg from "../images/steve/steve-greg.jpg";
import StevePili from "../images/steve/steve-pili.jpg";
import SteveSOSAChild from "../images/steve/steve-sosa-child.jpg";
import SteveCurtis from "../images/steve/steve-and-curtis.jpg";
import SteveGuitars from "../images/steve/steve-guitars.jpg";
import SteveTaylors2 from "../images/steve/steve-taylor-2.jpg";
import Route66 from "../images/steve/steve-route-66.jpg";
import SteveUkulele from "../images/steve/steve-ukulele.jpg";
import Windmill from "../images/steve/steve-windmill.jpg";

export const galleryImages = {
    zeroAndSlicks: {
        image: ZeroAndSlicks,
        title: "Zero and the Slicks",
        description: "1973",
    },

    genericBand1: {
        image: GenericBand1,
        title: "The Generic Band",
        description: "Nyssa, OR, 1985",
    },

    genericBand2: {
        image: GenericBand2,
        title: "The Generic Band",
        description: "Nyssa, OR, 1986",
    },

    genericBand3: {
        image: GenericBand3,
        title: "The Generic Band",
        description: "",
    },

    leiAloha: {
        image: LeiAloha,
        title: "The Lei Aloha Serenaders, 2009",
        description:
            "Left to Right: Pili Moreno, Kumu Pekelo Day, Steve Yungen",
    },

    turksSteve: {
        image: TurksSteve,
        title: "Turks Caicos",
        description: "2017",
    },

    grandSteve: {
        image: GrandSteve,
        title: "Steve Next to a Large Pit",
        description: "March 2017",
    },

    steveUkulele: {
        image: SteveUkulele,
        title: "Steve Warming Up Pre-Show",
        description: "2018",
    },

    steveGreg: {
        image: SteveGreg,
        title: "Steve and Greg",
        description: "",
    },

    route66: {
        image: Route66,
        title: "Route 66",
        description: "Album cover material?",
    },

    steveGuitars: {
        image: SteveGuitars,
        title: "Steve's Taylors",
        description: "",
    },

    stevePili: {
        image: StevePili,
        title: "Steve and Pili",
        description: "",
    },

    steveSOSAChild: {
        image: SteveSOSAChild,
        title: "Jacksonville Farmer's Market",
        description: "Southern Oregon Songwriters, 2019",
    },

    steveCurtis: {
        image: SteveCurtis,
        title: "Steve and Curtis",
        description: "Jacksonville Farmer's Market, 2019",
    },

    lapSteve: {
        image: LapSteve,
        title: "Steve with Lap Steel",
        description: "Florence, OR, 2015",
    },

    steve1986: {
        image: Steve1986,
        title: "Steve Shredding",
        description: "1986",
    },

    steveTaylors2: {
        image: SteveTaylors2,
        title: "Steve's Taylors",
        description: "",
    },

    windmill: {
        image: Windmill,
        title: "(Windmilling) at the beach",
        description: "Oregon coast",
    },
};
